import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataWindow, useDataWindows, useMetaData } from "../../api/useMetaDataApi";
import { useHealthcareProviders, usePayerProfileTopHCPExport } from "../../api/usePayerProfileApi";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import PageHeader from "../../components/Headers/PageHeader";
import MinimalSelect from "../../components/Inputs/MinimalSelect";
import Select from "../../components/Inputs/Select";
import Table from "../../components/Table/Table";
import { Column } from "../../components/Table/types";
import Tooltip from "../../components/Tooltip/Tooltip";
import {
  useClientIndicationFiltersconfiguration,
  useClientPlanProfileConfiguration,
} from "../../data/global/useProductConfiguration";
import { usePayerLandscapeDispatch, usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import { TopHealthcareProviders } from "../../generate";

const HealthcareProviderName = "healthcareProviderName" as const;
const useTableColumns = (channel: string) => {
  const { client: selectedClient } = useParams<{ client: string }>();
  const {
    sharedFilters: { timePeriod },
  } = usePayerLandscapeState();
  const { t, i18n } = useTranslation();

  const columns: Column<keyof TopHealthcareProviders>[] = [
    {
      label: "planProfile.healthcareProviders",
      align: "left",
      dataKey: "topHCPs",
      component: HealthcareProviderName,
    },
    {
      label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.fav_pct`)
        ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.fav_pct`)
        : t("planProfile.topHcpColumnHeaders.fav_pct"),
      align: "right",
      dataKey: "fav_pct",
      format: {
        suffix: "%",
      },
    },
    {
      label: i18n.exists(`planProfile.topHcpColumnHeaders.${selectedClient}.acc_pct`)
        ? t(`planProfile.topHcpColumnHeaders.${selectedClient}.acc_pct`)
        : t("planProfile.topHcpColumnHeaders.acc_pct"),
      align: "right",
      dataKey: "acc_pct",
      format: {
        suffix: "%",
      },
    },
    {
      label: t("planProfile.topHcpColumnHeaders.bobPctOvrl", { product: selectedClient }),
      align: "right",
      dataKey: "bob_pct_ovrl",
      format: {
        suffix: "%",
      },
    },
    {
      label: t("planProfile.topHcpColumnHeaders.prod_trx", { product: selectedClient }),
      align: "right",
      dataKey: "prod_trx",
      format: {
        thousandSeparator: true,
      },
    },
    {
      label: t(`planProfile.topHcpColumnHeaders.marketTrx`),
      align: "right",
      dataKey: "mkt_trx",
      format: {
        thousandSeparator: true,
      },
    },
    {
      label: t("planProfile.topHcpColumnHeaders.prod_share", { product: selectedClient }),
      align: "right",
      dataKey: "prod_share",
      format: {
        suffix: "%",
      },
    },
    {
      label: t("planProfile.topHcpColumnHeaders.chnl_prod_trx", { product: selectedClient, channel }),
      align: "right",
      dataKey: "chnl_prod_trx",
      format: {
        thousandSeparator: true,
      },
    },
    {
      label: t("planProfile.topHcpColumnHeaders.chnl_mkt_trx", { channel }),
      align: "right",
      dataKey: "chnl_mkt_trx",
      format: {
        thousandSeparator: true,
      },
    },
    {
      label: t("planProfile.topHcpColumnHeaders.chnl_prod_share", { product: selectedClient, channel }),
      align: "right",
      dataKey: "chnl_prod_share",
      format: {
        suffix: "%",
      },
    },
  ];
  return { columns };
};

const HealthcareProviders: React.FC = () => {
  const [t] = useTranslation();
  const { client: selectedClient, id, hcpID } = useParams<{ client: string; id: string; hcpID: string | undefined }>();
  const { data: metaData } = useMetaData(selectedClient);
  const { sharedFilters, detailFilters } = usePayerLandscapeState();
  const { updateSharedFilters, updateDetailFilters } = usePayerLandscapeDispatch();
  const { data: dataWindows } = useDataWindows(selectedClient);
  const timePeriodOptions = (dataWindows || []).map((dataWindow) => {
    return {
      label: t(`payerLandscape.timePeriodOptions.${dataWindow}`),
      value: dataWindow,
    };
  });
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const showTimePeriodSelection = (dataWindows || []).length > 1;
  const healthcareQuery = useHealthcareProviders(
    selectedClient,
    id,
    sharedFilters.timePeriod || defaultDataWindow,
    hcpID as string,
    detailFilters.indication || undefined,
  );

  const firstPageTableData = healthcareQuery?.data?.pages[0];
  const channel = firstPageTableData !== undefined && firstPageTableData !== null ? firstPageTableData[0].channel : "";
  const { columns } = useTableColumns(channel as string);
  const columnNames = columns.map((column) => t(column.label, column.translationParams));
  const columnKeys = columns.map((column) => column.dataKey);

  const indicationOptions = useClientIndicationFiltersconfiguration(selectedClient);
  const { indicationFiltering: showIndicationFiltering } = useClientPlanProfileConfiguration(selectedClient);

  //IF INDICATION SET, BUT SHOULDNT BE, SET TO UNDEFINED
  useEffect(() => {
    if (!showIndicationFiltering) {
      updateDetailFilters({ indication: undefined });
    }
  }, [indicationOptions, detailFilters.indication, showIndicationFiltering, updateDetailFilters]);

  //IF NO INDICATION SET, SET TO FIRST PRODUCT AVAILABLE
  useEffect(() => {
    if (
      indicationOptions &&
      showIndicationFiltering &&
      indicationOptions !== undefined &&
      indicationOptions.length > 0 &&
      detailFilters.indication === undefined
    ) {
      updateDetailFilters({ indication: indicationOptions[0].value });
    }
  }, [indicationOptions, detailFilters.indication, showIndicationFiltering, updateDetailFilters]);

  //Set the default timePeriod to last 12 months
  useEffect(() => {
    if (!sharedFilters.timePeriod && (dataWindows || []).length > 0) {
      updateSharedFilters({ timePeriod: defaultDataWindow });
    }
  }, [dataWindows, defaultDataWindow, sharedFilters.timePeriod, updateSharedFilters]);

  const exportTable = usePayerProfileTopHCPExport(
    id,
    columnKeys,
    columnNames,
    selectedClient,
    100,
    sharedFilters.timePeriod ? sharedFilters.timePeriod : undefined,
    detailFilters.indication || undefined,
  );

  return (
    <>
      <PageHeader title={t("planProfile.healthcareProviders")}></PageHeader>

      <BreadCrumbs />

      <div className="pb-4">
        <div className="flex flex-col lg:flex-row-reverse">
          <div className="lg:w-1/3">
            {showTimePeriodSelection ? (
              <Select
                value={sharedFilters.timePeriod}
                label={t("payerLandscape.timePeriod")}
                options={timePeriodOptions}
                handleChange={(value) => updateSharedFilters({ timePeriod: value })}
              />
            ) : null}
          </div>
          <div className="lg:w-2/12">
            {indicationOptions && showIndicationFiltering && (
              <MinimalSelect
                value={detailFilters.indication}
                handleChange={(value) => updateDetailFilters({ indication: value })}
                label={t("payerLandscape.indicationFor")}
                options={indicationOptions}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row-reverse mb-2">
        <Tooltip placement="bottom-end" tip={t(`planProfile.topProviderTooltip.${selectedClient}`) as string}>
          <InfoOutlined className="text-primary" />
        </Tooltip>
      </div>

      <Table columns={columns} query={healthcareQuery} stickyMargin={54} metaData={metaData} export={exportTable} />
    </>
  );
};

export default HealthcareProviders;
