import { Checkbox, FormControlLabel } from "@material-ui/core";
import CallMadeIcon from "@material-ui/icons/CallMade";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { pdfjs } from "react-pdf";
import { Link } from "react-router-dom";
import { usePrintDoc } from "../../api/usePrintDoc";
import ScrollPdfPreview from "../../components/PdfPreview/ScrollPdfPreview";
import { useClientPlanProfileConfiguration } from "../../data/global/useProductConfiguration";
import { HCPSpecificMessage, Message, PrintDoc } from "../../generate";
import { useClientRoutesAndLabels } from "../../ui/routes";
import { useModal } from "../Modal/useModal";

pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

interface Props {
  client: string;
  hcp: HCPSpecificMessage;
  sellSheet: Message;
  children: JSX.Element | JSX.Element[];
  onIncludePiChange?: (value: boolean) => void;
  includePi: boolean;
  isPrint?: boolean;
  showIsPI?: boolean;
}

const SellSheetModal: React.FC<Props> = ({
  client,
  hcp,
  sellSheet,
  children,
  onIncludePiChange,
  includePi,
  isPrint = true,
  showIsPI = true,
}) => {
  const [t] = useTranslation();
  const { setModal } = useModal();
  const rootRef = useRef<null | HTMLDivElement>(null);
  const routes = useClientRoutesAndLabels(client);
  const config = useClientPlanProfileConfiguration(client);

  const printDoc: PrintDoc = {
    client,
    baseDocName: sellSheet.messageName,
    prescID: hcp.prescID,
    includePI: includePi,
    isResource: false,
    isPrint,
  };
  const document = usePrintDoc({
    printDoc,
  });
  const url = document.data && document.data.downloadURL ? document.data.downloadURL : "";

  return (
    <div ref={rootRef} className="flex flex-col-reverse lg:flex-row -m-4 sm:-m-8">
      <ScrollPdfPreview url={url} isLoading={document.isLoading} client={client} />
      <div className="lg:min-w-500 lg:w-1/2 xl:w-5/12 p-6 flex flex-col justify-between">
        <div>
          <h1 className="text-lg sm:text-2xl font-bold text-brand">{sellSheet.messageName}</h1>
          {config.hiddenForGeneral ? (
            <span>{hcp.hcpName}</span>
          ) : (
            <Link
              to={routes.client.dpp.hcps.show.with({ client, id: hcp.prescID })}
              onClick={() => setModal(undefined)}
              className="flex items-center space-x-1 text-primary hover:text-primary-hover mt-1"
            >
              <span>{hcp.hcpName === undefined ? "" : hcp.hcpName}</span>
              <CallMadeIcon fontSize="small" />
            </Link>
          )}

          <div className="border border-steel bg-white my-6">
            <div className="bg-light-gray p-3">
              <h2 className="header-5-regular">{t("resourceLibrary.implementationGuide")}</h2>
            </div>
            <div className="p-3 body-2 text-slate-gray flex flex-col space-y-3 font-bold">
              {sellSheet.implementationGuide}
            </div>
          </div>

          {children && (
            <span className="mb-2 flex flex-col sm:flex-row lg:flex-col xl:flex-row space-x-0 sm:space-x-2 lg:space-x-0 xl:space-x-2 lg:space-y-2 xl:space-y-0">
              {children}
            </span>
          )}

          {showIsPI && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={includePi}
                  name="includePI"
                  color="primary"
                  onChange={() => onIncludePiChange && onIncludePiChange(!includePi)}
                />
              }
              label={t("queue.includePI")}
            />
          )}
        </div>
        <div className="flex justify-end">
          <button onClick={() => setModal(undefined)} className="btn btn-lg flex-grow sm:flex-grow-0 sm:w-32 btn-rules">
            {t("common.done")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SellSheetModal;
